var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"f0t_NE3Qx5vA-F7W_uSZV"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const SENTRY_DSN = publicRuntimeConfig.sentryDSN;

export const NEXTJS_FRONTEND_FINGERPRINT = 'NextJS Frontend';

const config = {
  dsn: SENTRY_DSN,
  environment: publicRuntimeConfig.appName,
  beforeSend: function (event, _hint) {
    if (
      event.fingerprint &&
      event.fingerprint[0] === NEXTJS_FRONTEND_FINGERPRINT
    ) {
      return event;
    }
    return null;
  },
  allowUrls: ['fernish.com', 'fernish.dev'],
  denyUrls: [],
  ignoreErrors: [
    /Load failed/i,
    /Loading chunk/i,
    /Failed to fetch/i,
    /Illegal invocation/i,
    /NetworkError when attempting to fetch resource/i,
    /Non-Error promise rejection captured with keys: currentTarget, detail, isTrusted, target/i,
    /The string did not match the expected pattern/i,
    /Unexpected token < in JSON at position 0/i,
    /No identifiers allowed directly after numeric literal/i,
    /function Error()/i,
    /undefined is not a function/i,
    /ceCurrentVideo.currentTime/i,
    /cancelled/i,
    /Unexpected end of input/i,
    /document.querySelectorAll(...).forEach is not a function/i,
    /Network request failed/i,
    /PaymentAutofillConfig/i,
    /Invalid or unexpected token/i,
    /WebJSBridge/i,
  ],
};

// if you want to enable sentry localhost, create a .env.local file and add this line:
// NEXT_PUBLIC_TEST_SENTRY_ON_LOCALHOST=true
if (process.env.NEXT_PUBLIC_TEST_SENTRY_ON_LOCALHOST) {
  config.allowUrls = [];
}

Sentry.init(config);
